import './font'
import '../shared/nav/collapsing_nav'
import '../shared/phone'
import './category_filter'
import '../shared/buyers_page_personalizer'

import AddToCartForms from '../shared/add_to_cart_forms'
import HotelIndex from '../shared/add_to_cart_forms/h4h_hotels/hotel_index'
import { store } from'../default/store'
import VueLoader from '../default/vue-loader'
import CartNav from '../shared/nav/nav_cart'
import Checkout from '../shared/checkout'
import DepositRedemptions from '../shared/deposit_redemptions'
import '../shared/stripe_checkout'

document.addEventListener('turbo:load', () => {
  AddToCartForms.loadBottomForm()
  CartNav.addCartToNavbar()
  DepositRedemptions.findCustomerDeposits()
  Checkout.loadWithAddOn()
  VueLoader.loadVueComponent(HotelIndex, document.getElementById('hotelIndex'), store)

})
