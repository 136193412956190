var pkg_grp = ''
var urlParams = ''

$(document)
  .on('turbo:load', function () {
    urlParams = new URLSearchParams(window.location.search)
    pkg_grp = urlParams.get('package-group')

    if (pkg_grp) {
      filterPackages()
    }

  })

function filterPackages() {
  $('.package-cube').each(function () {
    let categories = $(this).attr('data-category-names').split(',')
    let show = true

    for (let category of categories) {
      if (category == pkg_grp) {
        show = false
      }
    }
    if (show) {
      $(this).addClass('hidden')
    }
  })
}
